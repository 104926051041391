import React from 'react';
import { Button, Stack } from '@mui/material';
import { isSameDay } from 'date-fns';
import { css } from '@emotion/css';
import { useTheme } from '@mui/styles';

const MonthListStyle = (theme) => css`
  padding: ${theme.spacing(4)}px;
  display: flex;
`;

const MonthTextStyle = (isSameRange, theme) => css`
  color: black;
  text-align: center;
  font-weight: ${isSameRange ? 'bold' : 'normal'};
`;

const isSameRange = (first, second) => {
  const { startDate: fStart, endDate: fEnd } = first;
  const { startDate: sStart, endDate: sEnd } = second;
  if (fStart && sStart && fEnd && sEnd) {
    return isSameDay(fStart, sStart) && isSameDay(fEnd, sEnd);
  }
  return false;
};

const DefinedRanges = (props) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1} className={MonthListStyle(theme)}>
      {props.ranges.map((range, idx) => (
        <Button
          key={idx}
          size="small"
          variant={
            isSameRange(range, props.selectedRange) ? 'outlined' : 'text'
          }
          className={MonthTextStyle(
            isSameRange(range, props.selectedRange),
            theme
          )}
          onClick={() => props.setRange(range)}
        >
          {range.label}
        </Button>
      ))}
    </Stack>
  );
};

export default DefinedRanges;
