import { DateRange } from '../../types';
import { MonthMarkerKind } from '../month-markers';

export interface MonthProps {
  value: Date;
  marker: MonthMarkerKind;
  dateRange: DateRange;
  minDate: Date;
  maxDate: Date;
  navState: [boolean, boolean];
  setValue: (date: Date) => void;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (day: Date, marker: MonthMarkerKind) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
  };
  numberOfPastYears: number;
  numberOfFutureYears: number;
  /**
   * Date before/after which month and year selection options should be
   * disabled.
   */
  boundaryDate: Date;
}

export enum NavigationAction {
  Previous = -1,
  Next = 1,
}
