import React, { useRef, ChangeEvent } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setSnacksQueue } from 'redux/reducers/snacks';

type FileUploadProps = {
  /** callback that is passed the files  */
  handleFile: (files: File, fileUploadName: string) => void;
  /** props to be forwarded to the button */
  buttonProps?: ButtonProps;
  /** Label for the file upload button */
  label: string;
  /** file types accepted, defaults to csv and xlsx */
  accept?: string;
  loading?: boolean;
  disabled?: boolean;
};

/**
 * Component that renders a button that can be used to upload files
 */
export function FileUpload(props: FileUploadProps) {
  const { handleFile, buttonProps, label, accept, loading = false } = props;
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const dispatch = useDispatch();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event?.target?.files?.[0];

    if (!fileUploaded) {
      dispatch(
        setSnacksQueue({
          type: 'error',
          message:
            'Could not read the file. Please try again or use a different file.',
        })
      );
      return;
    }

    handleFile(fileUploaded, fileUploaded.name);
    if (event.target) event.target.value = ''; // reset the input value
  };

  return (
    <>
      <Button
        disabled={props.disabled}
        disableRipple
        disableFocusRipple
        onClick={handleClick}
        {...buttonProps}
      >
        {loading ? 'UPLOADING…' : label}
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept={accept}
      />
    </>
  );
}
