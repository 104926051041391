import * as React from 'react';
import {
  addMonths,
  addYears,
  endOfMonth,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  max,
  min,
  startOfMonth,
} from 'date-fns';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { DatePickerMenu } from './components/DatePickerMenu';
import { defaultRanges } from './defaults';
import { parseOptionalDate } from './utils';
import { MonthMarkerKind } from './month-markers';

const getValidatedMonths = (range, minDate, maxDate) => {
  let { startDate, endDate } = range;
  if (startDate && endDate) {
    const newStart = max([startDate, minDate]);
    const newEnd = min([endDate, maxDate]);

    return [
      newStart,
      isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd,
    ];
  } else {
    return [startDate, endDate];
  }
};

const DateRangePickerImpl = (props) => {
  const today = new Date();

  const {
    open,
    setOpen,
    onChange,
    initialDateRange,
    minDate,
    maxDate,
    definedRanges = defaultRanges,
    numberOfPastYears,
    numberOfFutureYears,
  } = props;

  const minDateValid = parseOptionalDate(minDate, addYears(today, -20));
  const maxDateValid = parseOptionalDate(maxDate, addYears(today, 3));
  const [intialFirstMonth, initialSecondMonth] = getValidatedMonths(
    initialDateRange || {},
    minDateValid,
    maxDateValid
  );

  const [dateRange, setDateRange] = React.useState({ ...initialDateRange });
  const [hoverDay, setHoverDay] = React.useState();
  const [firstMonth, setFirstMonth] = React.useState(intialFirstMonth || today);
  const [secondMonth, setSecondMonth] = React.useState(
    initialSecondMonth || addMonths(firstMonth, 1)
  );

  const [, /*selectingDate*/ setSelectingDate] = React.useState(false);

  const { startDate, endDate } = dateRange;

  /**
   *
   * @param {Date} date
   * @private
   */
  function _setFirstMonth(date) {
    setSelectingDate(false);
    setDateRange({ startDate: startOfMonth(date), endDate });
    setFirstMonth(date);
  }
  // handlers
  /**
   * @param {Date} date
   */
  const setFirstMonthValidated = (date) => {
    if (isBefore(startOfMonth(date), endOfMonth(secondMonth))) {
      _setFirstMonth(date);
    }
  };

  /**
   * @param {Date} date
   * @private
   */
  function _setSecondMonth(date) {
    setSelectingDate(false);
    setDateRange({ startDate, endDate: endOfMonth(date) });
    setSecondMonth(date);
  }

  /**
   * @param {Date} date
   */
  const setSecondMonthValidated = (date) => {
    if (isAfter(endOfMonth(date), startOfMonth(firstMonth))) {
      _setSecondMonth(date);
    }
  };

  const setDateRangeValidated = (range) => {
    let { startDate: newStart, endDate: newEnd } = range;
    if (newStart && newEnd) {
      range.startDate = newStart = max([newStart, minDateValid]);
      range.endDate = newEnd = min([newEnd, maxDateValid]);
      setDateRange(range);
      onChange(range);
      setFirstMonth(newStart);
      setSecondMonth(
        isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd
      );
    }
  };

  /**
   * @param {Date} day
   * @param {MonthMarkerKind} marker
   */
  function onDayClick(day, marker) {
    if (
      (startDate && isSameDay(day, startDate)) ||
      (endDate && isSameDay(day, endDate))
    )
      return;

    if (marker === MonthMarkerKind.FIRST_MONTH) {
      if (endDate && isBefore(endDate, day)) {
        return;
      }
      setDateRange({ startDate: day, endDate });
    }

    if (marker === MonthMarkerKind.SECOND_MONTH) {
      if (startDate && isAfter(startDate, day)) {
        return;
      }
      setDateRange({ startDate, endDate: day });
    }

    setHoverDay(day);
  }

  const onMonthNavigate = (marker, action) => {
    if (marker === MonthMarkerKind.FIRST_MONTH) {
      const firstNew = addMonths(firstMonth, action);
      if (isBefore(firstNew, secondMonth)) {
        _setFirstMonth(firstNew);
      }
    } else {
      const secondNew = addMonths(secondMonth, action);
      if (isBefore(firstMonth, secondNew)) {
        _setSecondMonth(secondNew);
      }
    }
  };

  const onDayHover = (date) => {
    if ((startDate && !endDate) || (!startDate && endDate)) {
      if (!hoverDay || !isSameDay(date, hoverDay)) {
        setHoverDay(date);
      }
    }
  };

  // helpers
  const inHoverRange = (day) => {
    return (
      startDate &&
      !endDate &&
      hoverDay &&
      isAfter(hoverDay, startDate) &&
      isWithinInterval(day, {
        start: startDate,
        end: hoverDay,
      })
    );
  };

  const helpers = {
    inHoverRange,
  };

  const handlers = {
    onDayClick,
    onDayHover,
    onMonthNavigate,
  };

  const okEnabled = startDate && endDate;
  function onApply() {
    if (okEnabled) {
      return onChange(dateRange);
    }
  }

  return open ? (
    <ClickAwayListener
      onClickAway={(event) => {
        // The click event couldn't find its target, ignore
        if (
          (event.target.nodeName === 'BODY' ||
            event.target.nodeName === 'LI') &&
          event.type === 'click'
        ) {
          return;
        }
        setOpen(false);
      }}
    >
      <DatePickerMenu
        dateRange={dateRange}
        minDate={minDateValid}
        maxDate={maxDateValid}
        ranges={definedRanges}
        firstMonth={firstMonth}
        secondMonth={secondMonth}
        setFirstMonth={setFirstMonthValidated}
        setSecondMonth={setSecondMonthValidated}
        setDateRange={setDateRangeValidated}
        helpers={helpers}
        handlers={handlers}
        numberOfPastYears={numberOfPastYears}
        numberOfFutureYears={numberOfFutureYears}
        onApply={onApply}
        setOpen={setOpen}
        okEnabled={okEnabled}
      />
    </ClickAwayListener>
  ) : (
    <></>
  );
};

export const DateRangePicker = DateRangePickerImpl;
