import qs from 'qs';
import algoliasearch from 'algoliasearch/lite';
import { isProdSite } from './util';
import { isEmpty } from 'lodash';
import { format, parseISO } from 'date-fns';
import { SearchState } from 'react-instantsearch-core';
import { DateRange, QueryParameters } from 'components/Explore/types';
import { getDefaultDateFilter } from 'components/Explore/AlgoliaSearch';

// #region Constants

const is_prod = isProdSite();
export const API_KEY = is_prod
  ? process.env.REACT_APP_ALGOLIA_API_KEY
  : process.env.REACT_APP_ALGOLIA_API_KEY_DEV;
export const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID as string,
  API_KEY as string
);

export const INDEX_NAME = is_prod ? 'prod_vendelux' : 'dev_vendelux';

export const INDEX_NAME_BY_TYPE = is_prod
  ? {
      event: 'prod_vendelux',
      org: 'prod_organizations',
      profile: 'prod_profiles',
    }
  : {
      event: 'dev_vendelux',
      org: 'dev_organizations',
      profile: 'dev_profiles',
    };

const FILTERS_MAP: {
  [key: string]: string;
} = {
  'attendees.n': 'people',
  'attendees.t': 'job',
};

// #endregion

interface SearchStateAndDateFilter {
  searchState: SearchState;
  dateFilter?: DateRange;
  optionalQueryParameters?: QueryParameters;
}

export const createURL = ({
  searchState,
  dateFilter,
  optionalQueryParameters = {},
}: SearchStateAndDateFilter) => {
  const refinementListArray = Object.entries(searchState.refinementList ?? []);
  const isDefaultRoute =
    !searchState.query &&
    searchState.page === 1 &&
    searchState.refinementList &&
    !refinementListArray.some(([, filter]) => filter.length !== 0) &&
    isEmpty(dateFilter);

  if (isDefaultRoute) {
    return '/app/events/search';
  }

  const queryParameters: QueryParameters = {
    ...optionalQueryParameters,
  };

  if (
    dateFilter &&
    !dateFilter.default &&
    dateFilter.startDate &&
    dateFilter.endDate
  ) {
    queryParameters.start_date = encodeURIComponent(
      format(dateFilter.startDate, 'yyyy-MM-dd')
    );
    queryParameters.end_date = encodeURIComponent(
      format(dateFilter.endDate, 'yyyy-MM-dd')
    );
  }

  if (searchState.query) {
    queryParameters.query = encodeURIComponent(searchState.query);
  }

  if (searchState.page !== 1) {
    queryParameters.page = searchState.page ?? 1;
  }

  refinementListArray.forEach(([key, value]) => {
    const mappedParam = FILTERS_MAP[key] || key;
    queryParameters[mappedParam] = (value || []).map(encodeURIComponent);
  });

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  });
  return `/app/events/search${queryString}`;
};

export const searchStateToUrl = ({
  searchState,
  dateFilter = getDefaultDateFilter(),
  optionalQueryParameters = {},
}: SearchStateAndDateFilter & {
  optionalQueryParameters?: QueryParameters;
}): string =>
  searchState || dateFilter
    ? createURL({ searchState, dateFilter, optionalQueryParameters })
    : '/app/events/search';

export function decodeStringArray(values: (string | qs.ParsedQs)[]): string[] {
  return values
    .filter((value): value is string => typeof value === 'string')
    .map(decodeURIComponent);
}

function parseAndDecodeArray(
  value: string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined
): string[] {
  if (!value) return [];
  if (typeof value === 'string') return decodeStringArray([value]);
  if (Array.isArray(value)) return decodeStringArray(value);
  return decodeStringArray([value]);
}

/**
 *
 * @param  {string} locationSearch
 * @param {boolean} initialLoad
 * @returns {SearchState}
 */
export const urlToSearchState = (
  locationSearch: string
): {
  searchState: SearchState;
  dateFilter: DateRange;
} => {
  const {
    query = '',
    page = 1,
    sponsors = [],
    people = [],
    job = [],
    country = [],
    region = [],
    topics = [],
    event_type = [],
    start_date = '',
    end_date = '',
  } = qs.parse(locationSearch.slice(1));

  // `qs` does not return an array when there's a single value.
  const allSponsors = parseAndDecodeArray(sponsors);
  const allCountries = parseAndDecodeArray(country);
  const allJobs = parseAndDecodeArray(job);
  const allPeople = parseAndDecodeArray(people);
  const allRegions = parseAndDecodeArray(region);
  const allTopics = parseAndDecodeArray(topics);
  const allEventTypes = parseAndDecodeArray(event_type);

  const defaultDateFilter = getDefaultDateFilter();

  return {
    searchState: {
      query: decodeURIComponent(query as string),
      page: Number(page),
      refinementList: {
        sponsors: allSponsors,
        country: allCountries,
        'attendees.n': allPeople,
        'attendees.t': allJobs,
        region: allRegions,
        topics: allTopics,
        event_type: allEventTypes,
      },
    },
    dateFilter: {
      startDate: start_date
        ? parseISO(decodeURIComponent(start_date as string))
        : defaultDateFilter.startDate,
      endDate: end_date
        ? parseISO(decodeURIComponent(end_date as string))
        : defaultDateFilter.endDate,
      default: start_date || end_date ? false : true,
    },
  };
};
