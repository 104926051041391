import {
  startOfWeek,
  endOfWeek,
  addWeeks,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfYear,
  endOfYear,
  addYears,
} from 'date-fns';

const getDefaultRanges = (date: Date) => [
  {
    label: 'Last month',
    startDate: startOfMonth(addMonths(date, -1)),
    endDate: endOfMonth(addMonths(date, -1)),
  },
  {
    label: 'Last week',
    startDate: startOfWeek(addWeeks(date, -1)),
    endDate: endOfWeek(addWeeks(date, -1)),
  },
  {
    label: 'Today',
    startDate: date,
    endDate: date,
  },
  {
    label: 'This week',
    startDate: startOfWeek(date),
    endDate: endOfWeek(date),
  },
  {
    label: 'Next week',
    startDate: startOfWeek(addWeeks(date, 1)),
    endDate: endOfWeek(addWeeks(date, 1)),
  },
  {
    label: 'This month',
    startDate: startOfMonth(date),
    endDate: endOfMonth(date),
  },
  {
    label: 'Next month',
    startDate: startOfMonth(addMonths(date, 1)),
    endDate: endOfMonth(addMonths(date, 1)),
  },
  {
    label: 'Next year',
    startDate: startOfYear(addYears(date, 1)),
    endDate: endOfYear(addYears(date, 1)),
  },
];

export const defaultRanges = getDefaultRanges(new Date());
