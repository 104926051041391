import React from 'react';
import { Paper, Grid, Typography, Divider, Button } from '@mui/material';
import {
  format,
  differenceInCalendarMonths,
  isWithinInterval,
  startOfMonth,
  endOfMonth,
  addMonths,
} from 'date-fns';
import Month from './Month';
import DefinedRanges from './DefinedRanges';
import { MonthMarkerKind } from '../month-markers';
import { css } from '@emotion/css';

const HeaderStyle = css`
  padding: 20px 70px;
`;

const ApplyButtonStyle = css`
  border-radius: 0;
`;

/**
 *
 * @param {object} props
 * @param {() => void} props.onApply
 * @param {boolean} props.okEnabled
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const DatePickerMenu = React.forwardRef((props, ref) => {
  const {
    ranges,
    dateRange,
    minDate,
    maxDate,
    firstMonth,
    setFirstMonth,
    secondMonth,
    setSecondMonth,
    setDateRange,
    helpers,
    handlers,
    numberOfPastYears,
    numberOfFutureYears,
    onApply,
    setOpen,
    okEnabled,
  } = props;

  const { startDate, endDate } = dateRange;
  const canNavigateCloser =
    differenceInCalendarMonths(secondMonth, firstMonth) >= 2;
  const commonProps = { dateRange, minDate, maxDate, helpers, handlers };

  const isHighestRange = !isWithinInterval(secondMonth, {
    start: minDate,
    end: endOfMonth(addMonths(maxDate, -1)),
  });

  const isLowestRange = !isWithinInterval(firstMonth, {
    start: startOfMonth(addMonths(minDate, 1)),
    end: maxDate,
  });

  const startDateText = startDate
    ? `: ${format(startDate, 'MMM d, yyyy')}`
    : '';
  const endDateText = endDate ? `: ${format(endDate, 'MMM d, yyyy')}` : '';

  return (
    <Paper
      ref={ref}
      elevation={0}
      square
      style={{
        borderRadius: '0.25rem',
      }}
    >
      <Grid container direction="row">
        <Grid item>
          <Grid container>
            <Grid
              item
              container
              className={HeaderStyle}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography variant="subtitle1">
                  <Typography
                    component="span"
                    fontWeight="bold"
                    display="inline"
                  >
                    Start date
                  </Typography>
                  {startDateText}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  <Typography
                    component="span"
                    fontWeight="bold"
                    display="inline"
                  >
                    End date
                  </Typography>
                  {endDateText}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" justifyContent="center">
              <Month
                {...commonProps}
                value={firstMonth}
                setValue={setFirstMonth}
                navState={[!isLowestRange, canNavigateCloser]}
                marker={MonthMarkerKind.FIRST_MONTH}
                numberOfPastYears={numberOfPastYears}
                numberOfFutureYears={numberOfFutureYears}
                boundaryDate={endDate}
              />
              <Divider orientation="vertical" flexItem variant="middle" />
              <Month
                {...commonProps}
                value={secondMonth}
                setValue={setSecondMonth}
                navState={[canNavigateCloser, !isHighestRange]}
                marker={MonthMarkerKind.SECOND_MONTH}
                numberOfPastYears={numberOfPastYears}
                numberOfFutureYears={numberOfFutureYears}
                boundaryDate={startDate}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <DefinedRanges
            selectedRange={dateRange}
            ranges={ranges}
            setRange={setDateRange}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="error"
                variant="contained"
                onClick={() => {
                  setOpen(false);
                }}
                className={ApplyButtonStyle}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={onApply}
                disabled={!okEnabled}
                className={ApplyButtonStyle}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
});
