import { Grid, IconButton, Select, MenuItem } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import React from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import {
  setMonth,
  getMonth,
  setYear,
  getYear,
  isAfter,
  isBefore,
  endOfMonth,
  subMonths,
  addMonths,
} from 'date-fns';

import FormControl from '@mui/material/FormControl';
import { MonthMarkerKind } from '../month-markers';

const styles = createStyles({
  iconContainer: {
    padding: 5,
  },
  icon: {
    padding: 10,
    '&:hover': {
      background: 'none',
    },
  },
  // formControl: {
  //   minWidth: 80,
  //   maxHeight: "100px",
  // },
});

const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

const generateYears = (
  relativeTo,
  count,
  numberOfPastYears,
  numberOfFutureYears
) => {
  const currentYear = relativeTo.getFullYear();

  if (numberOfPastYears && numberOfFutureYears) {
    const pastYears = Array(numberOfPastYears)
      .fill(0)
      .map((y, i) => currentYear - numberOfPastYears + i);

    const futureYears = Array(numberOfFutureYears)
      .fill(0)
      .map((y, i) => currentYear + 1 + i);

    return [...pastYears, currentYear, ...futureYears];
  }

  const half = Math.floor(count / 2);

  return Array(count)
    .fill(0)
    .map((y, i) => currentYear - half + i);
};

/**
 *
 * @param {Date} date
 * @param classes
 * @param {(date: Date) => void}setDate
 * @param nextDisabled
 * @param prevDisabled
 * @param onClickNext
 * @param onClickPrevious
 * @param numberOfPastYears
 * @param numberOfFutureYears
 * @param {MonthMarkerKind} marker
 * @param {Date} boundaryDate
 * @returns {JSX.Element}
 * @constructor
 */
const Header = ({
  date,
  classes,
  setDate,
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrevious,
  numberOfPastYears,
  numberOfFutureYears,
  marker,
  boundaryDate,
}) => {
  const thisYear = getYear(new Date());

  const handleMonthChange = (event) => {
    setDate(setMonth(date, parseInt(event.target.value)));
  };

  const handleYearChange = (event) => {
    setDate(setYear(date, parseInt(event.target.value)));
  };

  /**
   *
   * @param {number} yearItem
   */
  function _isYearDisabled(yearItem) {
    if (!boundaryDate) return false;
    const month = getMonth(date);
    return marker === MonthMarkerKind.FIRST_MONTH
      ? isAfter(addMonths(new Date(yearItem, month, 1), 1), boundaryDate)
      : isBefore(
          subMonths(endOfMonth(new Date(yearItem, month, 1)), 1),
          boundaryDate
        );
  }

  /**
   *
   * @param {number} monthItem
   */
  function _isMonthDisabled(monthItem) {
    if (!boundaryDate) return false;
    const year = getYear(date);
    return marker === MonthMarkerKind.FIRST_MONTH
      ? isAfter(addMonths(new Date(year, monthItem, 1), 0), boundaryDate)
      : isBefore(
          subMonths(endOfMonth(new Date(year, monthItem, 1)), 0),
          boundaryDate
        );
  }

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item className={classes.iconContainer}>
        <IconButton
          className={classes.icon}
          disabled={prevDisabled}
          onClick={onClickPrevious}
          size="large"
        >
          <ChevronLeft color={prevDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
      <Grid item>
        <FormControl className={classes.formControl}>
          <Select
            id="select-month"
            size="small"
            value={getMonth(date)}
            onChange={handleMonthChange}
            MenuProps={{ disablePortal: false }}
          >
            {MONTHS.map((month, idx) => (
              <MenuItem
                key={month}
                value={idx}
                id={month}
                disabled={_isMonthDisabled(idx)}
              >
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl className={classes.formControl}>
          <Select
            size="small"
            value={getYear(date)}
            onChange={handleYearChange}
            MenuProps={{ disablePortal: false }}
          >
            {generateYears(
              new Date(),
              30,
              numberOfPastYears,
              numberOfFutureYears
            ).map((year) => {
              if (!_isYearDisabled(year) || year === thisYear) {
                return (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                );
              }
              return null;
            })}
          </Select>
        </FormControl>

        {/* <Typography>{format(date, "MMMM YYYY")}</Typography> */}
      </Grid>
      <Grid item className={classes.iconContainer}>
        <IconButton
          className={classes.icon}
          disabled={nextDisabled}
          onClick={onClickNext}
          size="large"
        >
          <ChevronRight color={nextDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Header);
