import * as React from 'react';
import { IconButton, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { combine } from 'components/Explore/DateRangePicker/utils';

const styles = (theme) =>
  createStyles({
    leftBorderRadius: {
      borderRadius: '50% 0 0 50%',
    },
    rightBorderRadius: {
      borderRadius: '0 50% 50% 0',
    },
    buttonContainer: {
      display: 'flex',
    },
    button: {
      height: 36,
      width: 36,
      padding: 0,
    },
    buttonText: {
      lineHeight: 1.6,
    },
    outlined: {
      border: `1px solid #543fed`,
    },
    filled: {
      '&:hover': {
        backgroundColor: '#543fed',
      },
      backgroundColor: '#543fed',
    },
    highlighted: {
      backgroundColor: theme.palette.action.hover,
    },
    contrast: {
      color: theme.palette.primary.contrastText,
    },
    disabled: {
      opacity: 0,
    },
  });

const Day = (props) => {
  const { classes } = props;
  return (
    <div
      className={combine(
        classes.buttonContainer,
        props.startOfRange && classes.leftBorderRadius,
        props.endOfRange && classes.rightBorderRadius,
        !props.disabled && props.highlighted && classes.highlighted
      )}
    >
      <IconButton
        className={combine(
          classes.button,
          !props.disabled && props.outlined && classes.outlined,
          !props.disabled && props.filled && classes.filled,
          props.disabled && classes.disabled
        )}
        disabled={props.disabled}
        onClick={props.onClick}
        onMouseOver={props.onHover}
        size="large"
      >
        <Typography
          color={!props.disabled ? 'initial' : 'textSecondary'}
          className={combine(
            classes.buttonText,
            !props.disabled && props.filled && classes.contrast
          )}
          variant="body2"
        >
          {props.value}
        </Typography>
      </IconButton>
    </div>
  );
};

export default withStyles(styles)(Day);
