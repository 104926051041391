import { validate as validateUUID } from 'uuid';
import { avatarColors } from './theme';
import { isNumber } from 'lodash';

interface SortableItem {
  props: {
    value: string;
    count: number;
  };
}

// true if the site is running on vendelux.com, false if it is running locally or in a staging env
export function isProdSite(): boolean {
  return window.location.hostname === 'vendelux.com';
}

export function isProdOrStaging(): boolean {
  return (
    window.location.hostname === 'vendelux.com' ||
    window.location.hostname === 'staging.vendelux.com'
  );
}

// extracts a UUID from a URL path, taking care to handle query parameters
export function uuidFromUrl(url: string): string {
  const elements = url.split('/');
  for (let element of elements) {
    if (element.includes('?')) {
      element = element.substring(0, element.indexOf('?'));
    }
    if (validateUUID(element)) {
      return element;
    }
  }
  return '';
}

export function getThisUuid(): string {
  return uuidFromUrl(window.location.href);
}

export function getSlugPlusUuidFromUrl(url: string): string {
  // assumes URL like /app/obtype/this-is-the-slug/uuid/ is properly constructed
  return url.split('/').slice(-2).join('/');
}

export function formatNumber(
  number: number = 0,
  locale: string = 'en-US'
): string {
  return new Intl.NumberFormat(locale).format(number);
}

const usdFormat = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
export function formatUSD(number: number): string {
  return '$' + usdFormat.format(number);
}

export function getFormattedCost(
  lowCost: string | number,
  highCost: string | number
): string {
  const parsedLowCost = isNumber(lowCost) ? lowCost : parseFloat(lowCost);
  const parsedHighCost = isNumber(highCost) ? highCost : parseFloat(highCost);

  if (!parsedLowCost && !parsedHighCost) {
    return 'Free';
  }

  if (parsedLowCost === parsedHighCost) {
    return `$ ${formatNumber(parsedHighCost)}`;
  }

  if (!parsedHighCost) {
    return `$ ${formatNumber(parsedLowCost)}`;
  }

  return `$ ${formatNumber(parsedLowCost)} - ${formatNumber(parsedHighCost)}`;
}

/**
 * Pluralizes a word based on the given count.
 * @param {string} stem - The base word stem.
 * @param {number} count - The count used to determine singular or plural form.
 * @param {string} singular_suffix - The suffix to be added when count is 1.
 * @param {string} plural_suffix - The suffix to be added when count is not 1.
 * @returns {string} - The pluralized word.
 */
export function pluralize(
  stem: string,
  count: number,
  singular_suffix: string,
  plural_suffix: string
): string {
  if (count === 1) {
    return stem + singular_suffix;
  }
  return stem + plural_suffix;
}

export function viewEventLabelTextByAttendeeCount(
  attendee_count: number
): string {
  if (!attendee_count) return 'View event';
  if (attendee_count === 1 || attendee_count === 0) return 'View event';
  return `View ${formatNumber(attendee_count)} attendees`;
}

export function objectCompare(ob_1: any, ob_2: any) {
  const ob_1_keys = Object.keys(ob_1);
  const ob_2_keys2 = Object.keys(ob_2);

  let obj_are_eq = true;
  if (ob_1_keys.length !== ob_2_keys2.length) {
    obj_are_eq = false;
  }
  const is_object = (obj: any) => {
    return obj !== null && typeof obj === 'object';
  };
  Object.entries(ob_1).forEach(([key, obj_1_value]) => {
    if (!key.startsWith('_') && obj_are_eq) {
      const obj_2_value = ob_2[key];
      const valid_objects = is_object(obj_1_value) && is_object(obj_2_value);
      if (
        (valid_objects && !objectCompare(obj_1_value, obj_2_value)) ||
        (!valid_objects && obj_1_value !== obj_2_value)
      ) {
        obj_are_eq = false;
      }
    }
  });
  return obj_are_eq;
}

export const email_regex =
  /^(?:[\w!#$%&'*+\-\/=?^`{|}~]+\.)*[\w!#$%&'*+\-\/=?^`{|}~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/; // eslint-disable-line
export function downloadBase64File(
  contentType: string,
  base64Data: string,
  fileName: string
): void {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

// checks if two arrays are equal
export function arraysEqual<T>(a: T[], b: T[]): boolean {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

// checks if there are differences between two sets
export function setsHaveDifferences<T>(set1: Set<T>, set2: Set<T>): boolean {
  return (
    Array.from(set1).some((item) => !set2.has(item)) ||
    Array.from(set2).some((item) => !set1.has(item))
  );
}

// checks if there are at least two true values in the array
export function atLeastTwoTrue(arr: boolean[]): boolean {
  const trueCount = arr.filter((item) => item).length;
  return trueCount >= 2;
}

export function capitalizeFirstChar(str: string): string {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
}

const userLocale = navigator.language || 'en-US';
export function formatDateToLocalLong(inputDate: string): string {
  const date = new Date(inputDate);
  return new Intl.DateTimeFormat(userLocale, {
    dateStyle: 'long',
    timeStyle: 'long',
  }).format(date);
}

export function getRandomHexColor() {
  return avatarColors[Math.floor(Math.random() * avatarColors.length)];
}

// picks an element out of an array for a given arbitrary string. given the same string and array it will always return the same element
export function deterministicPickFromArray(
  string: string,
  array: string[]
): string {
  const index = string
    .split('')
    .reduce((acc, char) => acc ^ char.charCodeAt(0), 0);
  return array[index % array.length];
}

// sort bycount first then alphabetically, if null, then put it last
export function sortByCountThenAlpha(a: SortableItem, b: SortableItem) {
  const aValue = a.props.value;
  const bValue = b.props.value;
  if (aValue === null || aValue === '') return 1;
  if (bValue === null || bValue === '') return -1;
  if (a.props.count === b.props.count) {
    return aValue.localeCompare(bValue);
  }
  return b.props.count - a.props.count;
}

/**
 * Generates a duplicate name with a given suffix. Checks the array of existing names
 * to determine the appropriate incremented suffix number.
 *
 * @param params - The parameters for generating the duplicate name.
 * @param params.originalName - The original name.
 * @param params.suffix - The suffix to append or increment.
 * @param params.existingNames - Array of existing names to check for duplicates.
 * @returns The new name with the suffix adjusted.
 *
 * @example
 * getUniqueDuplicateName({ originalName: "Document", suffix: "copy", existingNames: ["Document", "Document (copy)"] });
 * // "Document (copy 2)"
 */
export function getUniqueDuplicateName({
  originalName,
  suffix,
  existingNames,
}: {
  originalName: string;
  suffix: string;
  existingNames: string[];
}): string {
  const pattern = new RegExp(`^(.*) \\(${suffix}(?: (\\d+))?\\)$`);
  let baseName = originalName;

  const maxSuffixNumber = existingNames.reduce((maxNumber, existingName) => {
    const match = existingName.match(pattern);
    if (match) {
      baseName = match[1];
      const currentNumber = match[2] ? parseInt(match[2], 10) : 1;
      return Math.max(maxNumber, currentNumber + 1);
    }
    return maxNumber;
  }, 1);

  return `${baseName} (${suffix}${
    maxSuffixNumber > 1 ? ` ${maxSuffixNumber}` : ''
  })`;
}

// helper function to convert values to numbers
export function convertNumberLikesToNumber(
  value: string | number | Record<string, number>
): number {
  if (typeof value === 'number') {
    return value;
  } else if (typeof value === 'string' && value.includes('$')) {
    return convertCurrencyToNumber(value);
  } else if (typeof value === 'string') {
    return convertDealValuesToNumber(value);
  } else if (typeof value === 'object' && value.amount !== undefined) {
    return value.amount;
  }
  return 0;
}

// helper function to convert values to numbers
export function convertDealValuesToNumber(value: string | unknown): number {
  if (typeof value === 'string') {
    // Check if the value represents "100K+"
    if (value.includes('-1K')) {
      // Special case for "251-1K": treat it as 251 - 1000
      const higherBound = parseFloat(value.split('-')[1].trim());
      return higherBound * 1000;
    }
    if (value === '100K+') {
      return 100000; // Return a large number greater than any other range
    }

    // Check if the value contains just 'K'
    if (value.includes('K')) {
      // Remove 'K' and convert to number, then multiply by 1000
      const numericValue = parseFloat(value.replace('K', '')) * 1000;
      return numericValue;
    } else {
      // Convert to number directly
      return parseFloat(value);
    }
  } else {
    return 0;
  }
}

// helper function to convert currency to number
export function convertCurrencyToNumber(value: string): number {
  return parseFloat(value.replace(/[^0-9.-]+/g, ''));
}
