import { Button, Paper, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Option, optionsApi } from 'redux/reducers/api/options';
import { Controller, useFormContext } from 'react-hook-form';
import { NewReportFieldValues } from './NewReport';
import { AutocompleteElement } from 'react-hook-form-mui';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { getInputElementProps } from 'components/common/Modal/FormModal';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export type ReportTitle =
  | 'Event insights report'
  | 'Sponsor reporting'
  | 'Event reporting'
  | 'Competitive analysis';

export const ReportSettings = () => {
  const form = useFormContext<NewReportFieldValues>();

  // get a list of my current prospect lists
  const { data: prospectLists, isFetching: fetchingProspectLists } =
    optionsApi.useGetProspectListOptionsQuery();

  // Datepicker... to be refactored into a separate component, consolidate with PLanner datepicker?
  const today = dayjs();

  new Date(Number.MIN_SAFE_INTEGER);
  const minDate = dayjs('2000-01-01');
  const maxDate = today.add(100, 'year');

  const confirmedOnly = form.watch('confirmed_only');

  return (
    <Paper
      sx={{
        p: 4,
        flex: 1,
      }}
    >
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Typography variant="h6">Filter by</Typography>
          <AutocompleteElement
            {...getInputElementProps({
              name: 'prospect_list',
              label: 'Prospect list',
              form,
            })}
            rules={{
              required: 'Select a prospect list',
            }}
            required
            aria-required
            options={prospectLists ?? []}
            autocompleteProps={{
              getOptionLabel: (option: Option) => option?.label ?? '',
              isOptionEqualToValue: (option: Option, value: Option) =>
                option?.uuid === value?.uuid,
              disabled: fetchingProspectLists,
              size: 'small',
            }}
          />
          <Controller
            name={'filter_date'}
            rules={{
              required: true,
              validate: ([start, end]) => {
                return Boolean(start && end) || 'Select a date range';
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <DateRangePicker
                  aria-required
                  {...field}
                  slots={{
                    field: SingleInputDateRangeField,
                  }}
                  {...getInputElementProps({
                    name: 'filter_date',
                    label: 'Date',
                    form,
                  })}
                  slotProps={{
                    field: {
                      required: true,
                      color: (fieldState.error && 'error') || null,
                      size: 'small',
                      InputProps: {
                        label: String(fieldState.error?.message) ?? 'Date',
                        error: Boolean(fieldState.error),
                        color: (fieldState.error && 'error') || null,
                      },
                    } as any, // the typing doesn't work if you change the field type
                  }}
                  {...{ minDate, maxDate }}
                />
              );
            }}
          />
        </Stack>
        <Stack gap={2} direction="row" alignItems="center">
          <Typography fontWeight="bold">Add filter:</Typography>
          <Stack gap={2} direction="row">
            <Button
              onClick={() => {
                form.setValue('confirmed_only', !confirmedOnly);
              }}
              variant="outlined"
              color="grey"
              startIcon={
                confirmedOnly ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
              }
            >
              Confirmed only
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {/* <Stack spacing={2}>
          <Typography variant="h6">Report type</Typography>
          <Typography>
            Select the type of events comparison report you'd like to create.{' '}
          </Typography>
          <Stack direction="row" gap={3} mb={8}>
            {reportTitles.map(({ key, title }, index) => (
              <ReportCardType
                key={index}
                setReportType={setReportType}
                value={key}
                title={title}
                activated={reportType === key}
                description=""
              />
            ))}
          </Stack>
        </Stack> */}
    </Paper>
  );
};
