import { Typography, Paper, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { TextFieldElement } from 'react-hook-form-mui';
import { getInputElementProps } from 'components/common/Modal/FormModal';

export const ReportSidebar = () => {
  const form = useFormContext();
  return (
    <Paper
      sx={{
        p: 4,
        height: '100%',
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6">General</Typography>
        <TextFieldElement
          required
          aria-required
          {...getInputElementProps({
            name: 'name',
            label: 'Report Name',
            form,
          })}
          validation={{
            required: 'Provide a name for your report',
          }}
        />
        {/* Phase 2 */}
        {/* <Divider /> */}
        {/* <Box mt={6} mb={4}>
        <Typography mb={1} variant="h6">
          Access
        </Typography>
        <Stack direction="row" alignItems={'center'} spacing={2}>
          <ProfilePhoto
            enableTooltip={false}
            image={
              'https://i.vendelux.com/JIKg2tc2jCZvh-HWyrqYdxFfYfE=/80x80/media/pic_folder/alex_reynolds.jpeg'
            }
            title={'B'}
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: theme.vdxColors.vdxBorderGrey,
              opacity: 0.8,
            }}
          />
          <ProfilePhoto
            enableTooltip={false}
            image={
              'https://i.vendelux.com/5rP0RgiGCQ_BLw5n_s5DVBY0Zow=/80x80/media/pic_folder/1min_Avatar.jpg'
            }
            title={'C'}
          />

          <ProfilePhoto
            enableTooltip={false}
            image={
              'https://i.vendelux.com/CbwposCOPOQmUFoPZX3A8XRsnxg=/80x80/media/pic_folder/5r8spaoKujtEoWU6qYsTQ4KJu4X2zJCHHhxAGZBsJPz1.jpg'
            }
            title={'A'}
          />
          <ProfilePhoto
            enableTooltip={false}
            image={
              'https://i.vendelux.com/3dHvmb1Br19ATeMtrttkcFADB4Y=/80x80/media/pic_folder/David_Vallner.jpeg'
            }
            title={'D'}
          />
        </Stack>
        <Typography fontSize={'medium'} mt={4} mb={4}>
          Created by Bojan Mitrevski. Shared with Alex, Bashir Jaji, Charlotte
          Jackson & 1 more
        </Typography>
        <Button color="primary" variant="roundedOutlined">
          Manage access
        </Button>
      </Box> */}
      </Stack>
    </Paper>
  );
};
