import React from 'react';
import { Button, Tooltip, Dialog } from '@mui/material';
import { startOfYear, endOfYear, addYears } from 'date-fns';
import { DateRangePicker } from './DateRangePicker';
import { useDisabledPill } from 'hooks/common/useDisabledPill';
import { DateRange } from 'components/Explore/types';
import { useUserState } from 'hooks/common/useUserState';
import { getFormattedDateInterval } from 'utils/date';
import CloseIcon from '@mui/icons-material/Close';
import { getDefaultDateFilter } from './AlgoliaSearch';

interface DatepickerProps {
  setDateFilter: (range: DateRange) => void;
  dateFilter: DateRange;
}

export const Datepicker = ({ setDateFilter, dateFilter }: DatepickerProps) => {
  const [open, setOpen] = React.useState(false);
  const { isAuthenticated, isFreeUser } = useUserState();

  const disabled = !isAuthenticated || isFreeUser;
  const disabledTooltip = `${
    !isAuthenticated ? 'Log in' : 'Upgrade'
  } to search events by date`;
  const disabledPill = useDisabledPill(disabled, disabledTooltip);

  const numberOfPastYears = 10;
  const numberOfFutureYears = 5;

  const hasDateSelected =
    !dateFilter.default && (dateFilter.startDate || dateFilter.endDate);

  return (
    <>
      <Tooltip {...disabledPill.tooltip}>
        <span {...disabledPill.span}>
          <Button
            onClick={() => {
              setOpen(true);
            }}
            variant={hasDateSelected ? 'rounded' : 'roundedOutlined'}
            color={
              !dateFilter.default &&
              (dateFilter.startDate || dateFilter.endDate)
                ? 'primary'
                : 'grey'
            }
            disabled={disabled}
            {...disabledPill.span}
            endIcon={
              hasDateSelected ? (
                <CloseIcon
                  onClick={() => setDateFilter(getDefaultDateFilter())}
                  fontSize="small"
                />
              ) : undefined
            }
          >
            {hasDateSelected
              ? getFormattedDateInterval(
                  dateFilter.startDate,
                  dateFilter.endDate
                )
              : 'Date'}
          </Button>
        </span>
      </Tooltip>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DateRangePicker
          open={open}
          setOpen={setOpen}
          initialDateRange={dateFilter}
          minDate={startOfYear(addYears(new Date(), -numberOfPastYears))}
          maxDate={endOfYear(addYears(new Date(), numberOfFutureYears))}
          numberOfPastYears={numberOfPastYears}
          numberOfFutureYears={numberOfFutureYears}
          onChange={(range: DateRange) => {
            setDateFilter({
              ...range,
              default: false,
            });
            setOpen(false);
          }}
          classes={{
            iconContainer: 'iconContainerVDX',
          }}
        />
      </Dialog>
    </>
  );
};
